<template>
    <modal ref="modalProductosInventario" titulo="Productos en inventario" tamano="modal-xl" no-aceptar no-cancelar>
        <div v-if="fecha" class="row mx-0 mb-3">
            <div class="col-12 text-general text-center f-500 f-16">
                Fecha de análisis de la frecuencia de compra y el valor promedio está entre {{ fecha }}
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12">
                <tabla-general 
                :mostrar-buscador="true" 
                :data="dataTable" 
                :columnas="dataColumns" 
                class-header="text-general f-16" 
                :alto="altoGrafica" 
                />
            </div>
            <div class="col-12 text-general f-15 mt-3">
                Nota: La frecuencia de compra y el valor promedio está analizado en los últimos 6 meses
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return{
            dataTable: [],
            dataColumns: [
                { valor: 'nombre', titulo: 'Producto' },
                { valor: 'cantidad_restante', titulo: 'Unidades de Inventario', class: 'text-center', ancho: 235 },
                { valor: 'total', titulo: 'Valor del inventario', ancho: 250 },
                { valor: 'frecuencia', titulo: 'Frecuencia de compra (en días)', class: 'text-center', ancho: 300 },
                { valor: 'promedio_total', titulo: 'Valor promedio de compra', ancho: 280 },
            ],
            fecha: ''
        }
    },
    computed: {
        altoGrafica(){
            return window.innerHeight <= 680 ? 'calc(100vh - 467px)' : 'calc(100vh - 500px)'
        }
    },
    methods: {
        toggle(data, fecha){
            this.fecha = fecha
            let datos = _.cloneDeep(data)
            datos.map(el => {
                el.cantidad_restante = this.agregarSeparadoresNumero(el.cantidad_restante)
                el.total = this.separadorNumero(el.total)
                el.frecuencia = this.agregarSeparadoresNumero(el.frecuencia,1)
                el.promedio_total = this.separadorNumero(el.promedio_total)
                return el
            })

            this.dataTable = datos
            this.$refs.modalProductosInventario.toggle();
        }
    }
}
</script>
